import {
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	withStyles,
	WithStyles,
	WithTheme
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { AppState } from "../../../../AppState";
import { Host, HOST_STATE } from "components/management/host/types";
import { Server } from "mdi-material-ui";
import HostStateComponent from "components/sharedComponents/hostState/HostStateComponent";
import HostUtils from "components/management/host/utils";
import { DEPLOYMENT_STATUS } from "components/management/cluster/types";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { BlinkingBadge } from "components/sharedComponents/BlinkingBadge/BlinkingBadge";
import { styles } from "./styles";

// component local state interface
interface State {
	isSelected: boolean;
}

// PROPS
interface LocalProps {
	host: Host;
}

interface DispatchProps {}

interface ReduxStateProps {
	hasRunningJobs: boolean;
}

type Props = LocalProps &
	ReduxStateProps &
	DispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class HostItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isSelected: false
		};
	}

	componentDidMount(): void {
		this.onRouteChanged();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	onRouteChanged() {
		const { clusterID, id: hostID } = this.props.host;
		const isSelected =
			this.props.location.pathname === `/clusters/${clusterID}/hosts/${hostID}`;

		this.setState({
			isSelected
		});
	}

	onItemClick = () => {
		const { clusterID, id: hostID } = this.props.host;
		this.props.history.push(`/clusters/${clusterID}/hosts/${hostID}`);
	};

	render(): React.ReactNode {
		const { theme, host, hasRunningJobs, classes } = this.props;
		const { isSelected } = this.state;

		const hostState: HOST_STATE = HostUtils.getHostState(host.deploymentStatus);

		const avatar = (
			<Avatar
				style={{
					backgroundColor:
						(hostState === DEPLOYMENT_STATUS.SUCCESS &&
							theme.palette.primary.main) ||
						undefined
				}}
			>
				<Server />
			</Avatar>
		);

		return (
			<>
				<ListItem
					className={classes.doubleNested}
					selected={isSelected}
					button
					onClick={this.onItemClick}
				>
					<ListItemAvatar>
						{hasRunningJobs ? (
							<BlinkingBadge variant="dot" color="primary">
								{avatar}
							</BlinkingBadge>
						) : (
							avatar
						)}
					</ListItemAvatar>
					<ListItemText
						primary={
							<Grid container direction="row" spacing={1}>
								<Grid item>
									<Typography variant="body2">{host.name}</Typography>
								</Grid>
							</Grid>
						}
						secondary={host.system}
					/>
					<HostStateComponent hostState={hostState} />
				</ListItem>
			</>
		);
	}
}

const makeHasRunningJobs = () =>
	createSelector(
		(state: AppState) => state.jobMonitor.runningJobList,
		(state: AppState, props: LocalProps) => props.host,
		(jobList: Job[], host: Host) =>
			jobList.some(
				(job: Job) =>
					job.description.includes(`"${host.name}"`) &&
					job.executionInfo.status === JOB_STATUS.RUNNING
			)
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const hasRunningJobsSelector = makeHasRunningJobs();

	return {
		hasRunningJobs: hasRunningJobsSelector(state, props)
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapGlobalStateToProps, {})(HostItemComponent))
);
