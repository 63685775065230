import { JOB_MONITOR_ACTION, JobMonitorAction, JobMonitorState } from "./types";
import { Job } from "../types";

const initialState: JobMonitorState = {
	jobsToMonitor: [],
	monitoredJobList: [],
	runningJobList: []
};

export function jobMonitorReducer(
	state: JobMonitorState = initialState,
	action: JobMonitorAction
): JobMonitorState {
	switch (action.type) {
		case JOB_MONITOR_ACTION.JOB_MONITOR_START:
			return {
				...state,
				jobsToMonitor: [...state.jobsToMonitor, action.payload.jobId]
			};

		case JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_MONITORED_SUCCEEDED:
			return { ...state, monitoredJobList: action.payload.jobList };

		case JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_RUNNING_SUCCEEDED:
			return { ...state, runningJobList: action.payload.jobList };

		case JOB_MONITOR_ACTION.JOB_MONITOR_STOP:
			return {
				...state,
				jobsToMonitor: state.jobsToMonitor.filter(
					(jobId: number) => action.payload.jobId !== jobId
				),
				monitoredJobList: state.monitoredJobList.filter(
					(job: Job) => action.payload.jobId !== job.id
				)
			};

		default:
			return state;
	}
}
