import {
	WsrepLocalStateMetric,
	METRICS_ACTION,
	LastCommittedMetricsFetchSucceededAction,
	WsrepLocalStateMetricsFetchSucceededAction,
	WsrepLastCommittedMetric
} from "modules/metricsStore/types";

export const lastCommittedMetricsFetchSucceeded = (
	metrics: Record<
		string,
		WsrepLastCommittedMetric>
): LastCommittedMetricsFetchSucceededAction => ({
	type: METRICS_ACTION.WSREP_LAST_COMMITTED_METRICS_FETCH_SUCCEEDED,
	payload: {
		metrics
	}
});

export const wsrepLocalStateMetricsFetchSucceeded = (
	metrics: WsrepLocalStateMetric[]
): WsrepLocalStateMetricsFetchSucceededAction => ({
	type: METRICS_ACTION.WSREP_LOCAL_STATE_METRICS_FETCH_SUCCEEDED,
	payload: {
		metrics
	}
});
