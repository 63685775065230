import {
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "@material-ui/core";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { Cluster } from "components/management/cluster/types";
import React, { ChangeEvent, FormEvent } from "react";
import { styles } from "./styles";
import {
	EC2SpecificSettings,
	FirewallRule,
	HOST_SYSTEM
} from "components/management/host/types";
import { NODE_DB_ENGINE } from "components/management/node/types";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import NodeUtils from "components/management/node/utils";
import HostUtils from "components/management/host/utils";
import { HelpOutline } from "@material-ui/icons";
import PasswordField from "components/sharedComponents/passwordField/passwordFieldComponent";
import DbEngineConfigButtonComponent from "components/sharedComponents/dbEngineConfigEditor/DbEngineConfigButtonComponent";
import EC2ConfigComponent from "../../../sharedComponents/ec2Config/EC2ConfigComponent";
import FirewallRulesEditorComponent from "../../../sharedComponents/firewallRulesEditor/FirewallRulesEditorComponent";
import { appStore } from "../../../../index";
import ClusterUtils from "../utils";
import { CLUSTER_TYPE } from "../clusterCreateWizard/types";
import SSHKeysEditor from "../../../sharedComponents/SSHKeysEditor/SSHKeysEditorComponent";

// component local state interface
interface LocalState {
	formValidation: {
		clusterName?: {
			isInvalid: boolean;
			message: string;
		};
		dbEngine?: {
			isInvalid: boolean;
			message: string;
		};
		hostSystem?: {
			isInvalid: boolean;
			message: string;
		};
		databaseSizeGiB?: {
			isInvalid: boolean;
			message: string;
		};
	};
	supportedSystems: HOST_SYSTEM[];
	supportedDbEngines: NODE_DB_ENGINE[];
}

interface LocalProps {
	cluster: Cluster;
	authorizedKeysSubtitle?: string;
	onSubmit?: (cluster: Cluster) => void;
	onChange?: (cluster: Cluster) => void;
	readOnly?: boolean;
}

// PROPS
type Props = LocalProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class ClusterFormComponent extends React.Component<Props, LocalState> {
	constructor(props: Props) {
		super(props);
		// console.log("clusterForm", DEFAULT_CLUSTER);

		this.state = {
			formValidation: {},
			supportedSystems: HostUtils.getSupportedHostSystems(
				props.cluster.sharedConfig.node.dbEngine
			),
			supportedDbEngines: NodeUtils.getSupportedDbEngines(
				props.cluster.sharedConfig.host.system
			)
		};
	}

	onSubmit = (e: FormEvent) => {
		e.preventDefault();
		console.log("onSubmit", e);

		const existingClusterNames = appStore
			.getState()
			.clusterList.map((cluster: Cluster) => cluster.name);

		console.log(
			"existing cluster names",
			existingClusterNames,
			this.props.cluster.name,
			this.props
		);

		if (existingClusterNames.includes(this.props.cluster.name)) {
			console.log("cluster name taken!");

			this.setState((state: LocalState) => ({
				...state,
				formValidation: {
					...state.formValidation,
					clusterName: {
						...state.formValidation.clusterName,
						isInvalid: true,
						message: "Please enter unique cluster name."
					}
				}
			}));

			return;
		}

		!this.props.readOnly &&
			this.props.onSubmit &&
			this.props.onSubmit(this.props.cluster);
	};

	render(): React.ReactNode {
		const { readOnly, cluster, onChange } = this.props;
		const { supportedSystems, supportedDbEngines, formValidation } = this.state;

		const clusterType = ClusterUtils.getClusterType(cluster);

		const showDBRootPassword =
			clusterType === CLUSTER_TYPE.MONITORED || (readOnly && cluster.id);

		return (
			<form
				id={`clusterForm${readOnly ? "-readonly" : ""}`}
				onSubmit={this.onSubmit}
			>
				<Grid container item direction="column">
					<Grid item>
						<Typography variant="subtitle1">Cluster configuration</Typography>
					</Grid>
					<Grid container item direction="row" spacing={2}>
						<Grid item xs={12} sm={6}>
							<FormControl
								onInvalid={(e: FormEvent): void => {
									e.preventDefault();
									const form = e.target as HTMLFormElement;

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											clusterName: {
												...state.formValidation.clusterName,
												isInvalid: true,
												message: form.validationMessage
											}
										}
									}));
								}}
								error={formValidation.clusterName?.isInvalid}
								fullWidth={true}
								required
							>
								<TextField
									label="Cluster name"
									error={formValidation.clusterName?.isInvalid}
									autoFocus={!readOnly}
									required
									autoComplete="off"
									inputProps={{
										readOnly: readOnly,
										maxLength: 20,
										"data-cy": "cluster-name"
									}}
									margin="dense"
									name="clusterName"
									helperText={formValidation.clusterName?.message}
									value={cluster.name}
									onChange={(e: ChangeEvent) => {
										const field = e.target as HTMLFormElement;

										console.log("cluster name change", field.value);

										onChange &&
											onChange({
												...cluster,
												name: field.value
											});

										if (field.checkValidity()) {
											this.setState((state: LocalState) => ({
												formValidation: {
													...state.formValidation,
													clusterName: {
														...state.formValidation.clusterName,
														isInvalid: false,
														message: ""
													}
												}
											}));
										} else {
											this.setState((state: LocalState) => ({
												...state,
												formValidation: {
													...state.formValidation,
													clusterName: {
														...state.formValidation.clusterName,
														isInvalid: true,
														message: field.validationMessage
													}
												}
											}));
										}
									}}
								/>
							</FormControl>
						</Grid>
						<Grid item sm={6} xs={12}>
							<FormControl
								style={{ marginTop: "16px" }}
								required
								fullWidth={true}
								margin="dense"
								onInvalid={(e: FormEvent): void => {
									e.preventDefault();
									const form = e.target as HTMLFormElement;
									console.log("invalid dbengine", form.validationMessage);
									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											dbEngine: {
												...state.formValidation.dbEngine,
												isInvalid: true,
												message: form.validationMessage
											}
										}
									}));
								}}
								error={formValidation.dbEngine?.isInvalid}
							>
								<InputLabel htmlFor="node-db-engine">Node DB Engine</InputLabel>
								<Select
									displayEmpty={true}
									required
									placeholder={``}
									fullWidth={true}
									data-cy="node-db-engine-select-container"
									readOnly={readOnly}
									value={cluster.sharedConfig.node.dbEngine || ""}
									onChange={(e) => {
										const dbEngine = NodeUtils.getNodeDbEngineEnum(
											e.target.value as string
										);
										const supportedSystems =
											HostUtils.getSupportedHostSystems(dbEngine);

										let system = cluster.sharedConfig.host.system;
										if (!supportedSystems.includes(system))
											system = supportedSystems[-1];

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													node: {
														...cluster.sharedConfig.node,
														dbEngine
													},
													host: {
														...cluster.sharedConfig.host,
														system
													}
												}
											});
										this.setState((state: LocalState) => ({
											supportedSystems,
											formValidation: {
												...state.formValidation,
												dbEngine: {
													...state.formValidation.dbEngine,
													isInvalid: false,
													message: ""
												}
											}
										}));
									}}
									inputProps={{
										id: "node-db-engine",
										"data-cy": "node-db-engine-select"
									}}
								>
									{Object.values(NODE_DB_ENGINE).map((db_engine: string) => (
										<MenuItem key={db_engine} value={db_engine}>
											{`${db_engine}${
												(!supportedDbEngines.includes(
													NodeUtils.getNodeDbEngineEnum(db_engine)
												) &&
													cluster.sharedConfig.host.system &&
													" (Not supported on " +
														cluster.sharedConfig.host.system +
														")") ||
												""
											}`}
										</MenuItem>
									))}
								</Select>
								{formValidation.dbEngine?.isInvalid && (
									<FormHelperText>
										{formValidation.dbEngine?.message}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{clusterType === CLUSTER_TYPE.MANAGED && (
							<>
								<Grid item sm={6} xs={12}>
									<Tooltip
										title={
											"The actual disk size will be larger to accommodate for cache, SSTs, and other features necessary for optimal DB engine operation."
										}
									>
										<FormControl
											onInvalid={(e: FormEvent): void => {
												e.preventDefault();
												const form = e.target as HTMLFormElement;

												this.setState((state: LocalState) => ({
													...state,
													formValidation: {
														...state.formValidation,
														databaseSizeGiB: {
															...state.formValidation.databaseSizeGiB,
															isInvalid: true,
															message: form.validationMessage
														}
													}
												}));
											}}
											error={formValidation.databaseSizeGiB?.isInvalid}
											fullWidth={true}
											required
										>
											<TextField
												error={formValidation.databaseSizeGiB?.isInvalid}
												helperText={formValidation.databaseSizeGiB?.message}
												fullWidth={true}
												type="number"
												required
												margin="dense"
												label="Expected max DB size"
												value={cluster.sharedConfig.host.databaseSizeGiB}
												onChange={(e) => {
													const databaseSizeGiB = parseInt(e.target.value);

													onChange &&
														onChange({
															...cluster,
															sharedConfig: {
																...cluster.sharedConfig,
																host: {
																	...cluster.sharedConfig.host,
																	databaseSizeGiB
																}
															}
														});

													this.setState((state: LocalState) => ({
														formValidation: {
															...state.formValidation,
															databaseSizeGiB: {
																...state.formValidation.databaseSizeGiB,
																isInvalid: false,
																message: ""
															}
														}
													}));
												}}
												inputProps={{
													id: "ebs-volume-size",
													"data-cy": "ebs-volume-size",
													readOnly: readOnly,
													min: 1,
													max: 65536
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">GiB</InputAdornment>
													)
												}}
											/>
										</FormControl>
									</Tooltip>
								</Grid>
								<Grid item sm={6} xs={12}>
									<FirewallRulesEditorComponent
										readOnly={readOnly}
										firewallRules={cluster.firewallRules}
										onRemove={(removedRule: FirewallRule) => {
											onChange &&
												onChange({
													...cluster,
													firewallRules: cluster.firewallRules.filter(
														(rule: FirewallRule) =>
															rule.description !== removedRule.description
													)
												});
										}}
										onAdd={(addedRule: FirewallRule) => {
											onChange &&
												onChange({
													...cluster,
													firewallRules: [...cluster.firewallRules, addedRule]
												});
										}}
									/>
								</Grid>
							</>
						)}
						{showDBRootPassword && (
							<Grid item sm={6} xs={12}>
								<PasswordField
									required={true}
									value={cluster ? cluster.sharedConfig.node.rootPassword : ""}
									label="DB Root Password"
									onChange={(e: ChangeEvent) => {
										const target = e.target as HTMLFormElement;

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													node: {
														...cluster.sharedConfig.node,
														rootPassword: target.value as string
													}
												}
											});
									}}
								/>
							</Grid>
						)}

						{(readOnly && cluster.id) ||
						clusterType === CLUSTER_TYPE.MANAGED ? (
							<Grid item sm={6} xs={12}>
								<SSHKeysEditor
									clusterType={clusterType}
									readOnly={readOnly || false}
									authorizedKeys={
										cluster.sharedConfig.host.authorizedKeys || []
									}
									onAdd={(key: string): void => {
										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													host: {
														...cluster.sharedConfig.host,
														authorizedKeys: [
															...(cluster.sharedConfig.host.authorizedKeys ||
																[]),
															key
														]
													},
													node: {
														...cluster.sharedConfig.node
													}
												}
											});
									}}
									onRemove={(deletedKey: string): void => {
										const filteredKeys =
											this.props.cluster.sharedConfig.host.authorizedKeys?.filter(
												(publicKey: string) => publicKey !== deletedKey
											);

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													host: {
														...cluster.sharedConfig.host,
														authorizedKeys: filteredKeys
													},
													node: {
														...cluster.sharedConfig.node
													}
												}
											});
									}}
								/>
							</Grid>
						) : undefined}
					</Grid>
				</Grid>
				<Grid container item direction="column">
					<Typography variant="subtitle1">
						Node default configuration{" "}
						<Tooltip title="Options to be set as default when adding node to the cluster. All options can be changed before node deployment. ">
							<HelpOutline fontSize="small" />
						</Tooltip>
					</Typography>
					<Grid container direction="row" spacing={2}>
						<Grid item sm={6} xs={12}>
							<FormControl
								required={true}
								fullWidth={true}
								margin="dense"
								onInvalid={(e: FormEvent): void => {
									e.preventDefault();
									const form = e.target as HTMLFormElement;
									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											hostSystem: {
												...state.formValidation.hostSystem,
												isInvalid: true,
												message: form.validationMessage
											}
										}
									}));
								}}
								error={formValidation.hostSystem?.isInvalid}
							>
								<InputLabel htmlFor="host-system">Host system</InputLabel>
								<Select
									required
									displayEmpty={true}
									data-cy="host-system-select-container"
									fullWidth={true}
									readOnly={readOnly}
									value={cluster.sharedConfig.host.system || ""}
									onChange={(e) => {
										const system = HostUtils.getHostSystemEnum(
											e.target.value as string
										);
										const supportedDbEngines =
											NodeUtils.getSupportedDbEngines(system);

										let dbEngine = cluster.sharedConfig.node.dbEngine;
										if (!supportedDbEngines.includes(dbEngine))
											dbEngine = supportedDbEngines[-1];

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													host: {
														...cluster.sharedConfig.host,
														system
													},
													node: {
														...cluster.sharedConfig.node,
														dbEngine
													}
												}
											});

										this.setState((state: LocalState) => ({
											supportedDbEngines,
											formValidation: {
												...state.formValidation,
												hostSystem: {
													...state.formValidation.hostSystem,
													isInvalid: false,
													message: ""
												}
											}
										}));
									}}
									inputProps={{
										id: "host-system",
										"data-cy": "host-system-select"
									}}
								>
									{Object.values(HOST_SYSTEM).map((system: string) => (
										<MenuItem key={system} value={system}>
											{`${system}${
												(!supportedSystems.includes(
													HostUtils.getHostSystemEnum(system)
												) &&
													cluster.sharedConfig.node.dbEngine &&
													" (Not supported by " +
														cluster.sharedConfig.node.dbEngine +
														")") ||
												""
											}`}
										</MenuItem>
									))}
								</Select>
								{formValidation.hostSystem?.isInvalid && (
									<FormHelperText>
										{formValidation.hostSystem?.message}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						{!cluster.unmanaged && (
							<Grid item sm={6} xs={12}>
								<DbEngineConfigButtonComponent
									config={cluster.sharedConfig.node.userConfig || ""}
									readOnly={readOnly || false}
									onChange={(config: string) => {
										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													node: {
														...cluster.sharedConfig.node,
														userConfig: config
													}
												}
											});
									}}
									subtitleText={
										readOnly
											? "This is default DB engine configuration that will be used as default when deploying nodes"
											: "Here you can enter custom DB engine configuration that will be used as default when deploying nodes"
									}
								/>
							</Grid>
						)}
					</Grid>

					{cluster.sharedConfig.host.type === "ec2" &&
						cluster.sharedConfig.host.hostTypeSpecific && (
							<>
								<EC2ConfigComponent
									config={cluster.sharedConfig.host.hostTypeSpecific}
									readOnly={readOnly}
									requirements={{
										minRAM:
											cluster.sharedConfig.node.dbEngine ===
											NODE_DB_ENGINE.MYSQL_8_0
												? 2048
												: 1024
									}}
									onChange={(ec2Config: EC2SpecificSettings) => {
										console.log("onEC2 config change", ec2Config);

										onChange &&
											onChange({
												...cluster,
												sharedConfig: {
													...cluster.sharedConfig,
													host: {
														...cluster.sharedConfig.host,
														hostTypeSpecific: ec2Config
													}
												}
											});
									}}
								/>
							</>
						)}
				</Grid>
			</form>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	withRouter(ClusterFormComponent)
);
