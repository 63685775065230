import * as React from "react";
import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";
import { Host, HOST_TYPE } from "components/management/host/types";
import { Cluster } from "components/management/cluster/types";
import { RouteComponentProps, StaticContext, withRouter } from "react-router";
import EC2ConfigComponent from "components/sharedComponents/ec2Config/EC2ConfigComponent";
import SSHKeysEditor from "../../../sharedComponents/SSHKeysEditor/SSHKeysEditorComponent";
import ClusterUtils from "../../cluster/utils";
import { CLUSTER_TYPE } from "../../cluster/clusterCreateWizard/types";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";

interface LocalState {}

interface LocalProps {
	cluster: Cluster;
	host: Host;
}

// PROPS
interface ReduxStateProps {}

type Props = LocalProps &
	ReduxStateProps &
	RouteComponentProps<any, StaticContext, any>;

class NodeFormComponent extends React.Component<Props, LocalState> {
	render() {
		const { cluster, host } = this.props;

		const clusterType = ClusterUtils.getClusterType(cluster);

		const readOnly = true;

		return (
			<>
				<form id={`strayHostForm}`}>
					{host && (
						<>
							<Grid container direction="column">
								{/*<Grid item container direction="row">*/}
								{/*	<Typography variant="subtitle1">*/}
								{/*		Node configuration*/}
								{/*	</Typography>*/}
								{/*</Grid>*/}
								<Grid container direction="row" spacing={2}>
									<Grid item sm={6} xs={12}>
										<FormControl fullWidth={true} required>
											<TextField
												margin="dense"
												required
												label={"Host name"}
												inputProps={{
													minLength: 3,
													maxLength: 20,
													"data-cy": "stray-host-name"
												}}
												autoComplete="off"
												value={host.name}
											/>
										</FormControl>
									</Grid>

									{clusterType !== CLUSTER_TYPE.MANAGED && (
										<HiddenJs smDown>
											<Grid item sm={6} xs={12} />
										</HiddenJs>
									)}

									<Grid item sm={6} xs={12}>
										<FormControl required fullWidth={true} margin="normal">
											<InputLabel htmlFor="host-system">Host system</InputLabel>
											<Select
												required
												data-cy="host-system-select-container"
												fullWidth={true}
												value={host.system}
												inputProps={{
													id: "host-system",
													"data-cy": "host-system-select",
													readOnly: readOnly
												}}
											>
												<MenuItem key={host.system} value={host.system}>
													{host.system}
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item sm={6} xs={12}>
										<FormControl fullWidth={true} required>
											<TextField
												fullWidth={true}
												type="number"
												required
												margin="dense"
												label="Segment"
												value={host.segment}
												inputProps={{
													id: "host-segment",
													"data-cy": "host-segment",
													readOnly: readOnly,
													min: 0
												}}
											/>
										</FormControl>
									</Grid>
									{/*</Grid>*/}
									{host.type === HOST_TYPE.EC2 && host.hostTypeSpecific && (
										<>
											<EC2ConfigComponent
												config={host.hostTypeSpecific}
												readOnly={readOnly}
												requirements={{
													minRAM: 1024
												}}
											/>
										</>
									)}
									{host.type === HOST_TYPE.UNMANAGED && (
										<>
											<Grid item sm={6} xs={12}>
												<FormControl fullWidth={true} required>
													<TextField
														fullWidth={true}
														margin="dense"
														required
														label="SSH Address"
														value={host.ssh && host.ssh.address}
														inputProps={{ readOnly }}
													/>
												</FormControl>
											</Grid>
											<Grid item sm={6} xs={12}>
												<FormControl fullWidth={true} required>
													<TextField
														fullWidth={true}
														type="number"
														required
														margin="dense"
														label="SSH Port"
														value={host.ssh && host.ssh.port}
														inputProps={{ readOnly, min: 0, max: 65535 }}
													/>
												</FormControl>
											</Grid>
										</>
									)}
									<Grid item sm={6} xs={12}>
										<SSHKeysEditor
											clusterType={clusterType}
											authorizedKeys={host.authorizedKeys}
											inheritedAuthorizedKeys={
												cluster ? cluster.sharedConfig.host.authorizedKeys : []
											}
											readOnly={readOnly || false}
										/>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</form>
			</>
		);
	}
}

export default withRouter(NodeFormComponent);
