import {
	Avatar,
	Collapse,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	WithStyles,
	withStyles,
	WithTheme
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Layers } from "@material-ui/icons";
import { AppState } from "AppState";
import NodeItemComponent from "components/management/treeView/nodeItem/NodeItemComponent";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { styles } from "./styles";
import { createSelector } from "reselect";
import { Node } from "components/management/node/types";
import { Host } from "components/management/host/types";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { BlinkingBadge } from "components/sharedComponents/BlinkingBadge/BlinkingBadge";

// component local state interface
interface State {
	isExpanded: boolean;
}

// PROPS
interface LocalProps {
	clusterID: number;
	segment: number;
}

interface DispatchProps {}

interface ReduxStateProps {
	filteredNodeList: Node[];
	// zombieHostList: Host[];
	hasRunningJobs: boolean;
}

type Props = LocalProps &
	DispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class SegmentItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isExpanded: true
		};
	}

	handleExpandClick = () => {
		this.setState((state) => ({ isExpanded: !state.isExpanded }));
	};

	render(): React.ReactNode {
		const { classes, segment, theme, filteredNodeList, hasRunningJobs } =
			this.props;
		const { isExpanded } = this.state;

		const avatar = (
			<Avatar style={{ backgroundColor: theme.palette.primary.main }}>
				<Layers style={{ transform: "rotate(-90deg)" }} />
			</Avatar>
		);

		return (
			<>
				<ListItem button className={classes.nested}>
					<ListItemAvatar>
						{hasRunningJobs ? (
							<BlinkingBadge variant="dot" color="primary">
								{avatar}
							</BlinkingBadge>
						) : (
							avatar
						)}
					</ListItemAvatar>
					<ListItemText primary={`Segment ${segment}`} />
					{isExpanded ? (
						<ExpandLess onClick={this.handleExpandClick} />
					) : (
						<ExpandMore onClick={this.handleExpandClick} />
					)}
				</ListItem>
				<Collapse in={isExpanded} timeout="auto" unmountOnExit>
					<List disablePadding>
						{filteredNodeList
							.sort((node1: Node, node2: Node) =>
								node1.name.toLowerCase().localeCompare(node2.name.toLowerCase())
							)
							.map((node: any) => {
								return (
									<NodeItemComponent
										data-cy="tree-view-node"
										key={node.id}
										node={node}
									/>
								);
							})}
					</List>
					{/*<List disablePadding>*/}
					{/*	{zombieHostList.map((host: Host) => {*/}
					{/*		return (*/}
					{/*			<HostItemComponent*/}
					{/*				data-cy="tree-view-host"*/}
					{/*				key={host.id}*/}
					{/*				host={host}*/}
					{/*			/>*/}
					{/*		);*/}
					{/*	})}*/}
					{/*</List>*/}
				</Collapse>
			</>
		);
	}
}

// selectors
const makeFilteredNodeListSelector = () =>
	createSelector(
		(state: AppState) => state.nodeList,
		(state: AppState) => state.hostList,
		(state: AppState, props: LocalProps) => props.clusterID,
		(state: AppState, props: LocalProps) => props.segment,
		(
			nodeMap: Map<number, Node[]>,
			hostMap: Map<number, Host[]>,
			clusterID: number,
			segment: number
		): Node[] => {
			const nodeList = nodeMap.get(clusterID) || [];
			const hostList = hostMap.get(clusterID) || [];

			return nodeList.filter((node: Node) => {
				const nodeSegment = hostList.find((host: Host) => {
					return host.id === node.hostID;
				})?.segment;

				return nodeSegment === segment;
			});
		}
	);

// const makeZombieHostListSelector = () =>
// 	createSelector(
// 		(state: AppState) => state.hostList,
// 		(state: AppState, props: LocalProps) => props.clusterID,
// 		(state: AppState, props: LocalProps) => props.segment,
// 		(
// 			hostMap: Map<number, Host[]>,
// 			clusterID: number,
// 			segment: number
// 		): Host[] => {
// 			const hostList = hostMap.get(clusterID) || [];
// 			return hostList.filter((host: Host) => {
// 				return host.segment === segment && !host.nodeID;
// 			});
// 		}
// 	);

const makeHasRunningJobs = () =>
	createSelector(
		[
			(state: AppState) => state.jobMonitor.runningJobList,
			makeFilteredNodeListSelector()
		],
		(jobList: Job[], nodes: Node[]) => {
			const nodeIDs = nodes.map((node: Node) => node.id);
			const hostIDs = nodes.map((node: Node) => node.hostID);
			const clusterID = nodes[0]?.clusterID;

			return jobList.some(
				(job: Job) =>
					(nodeIDs.includes(job.meta.node_id || -1) ||
						hostIDs.includes(job.meta.host_id || -1)) &&
					clusterID === job.meta.cluster_id &&
					job.executionInfo.status === JOB_STATUS.RUNNING
			);
		}
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const filteredNodeListSelector = makeFilteredNodeListSelector();
	// const filteredZombieListSelector = makeZombieHostListSelector();
	const hasRunningJobsSelector = makeHasRunningJobs();

	return {
		filteredNodeList: filteredNodeListSelector(state, props),
		// zombieHostList: filteredZombieListSelector(state, props),
		hasRunningJobs: hasRunningJobsSelector(state, props)
	};
};

const mapGlobalDispatchToProps = () => {
	return {};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(
		connect(
			mapGlobalStateToProps,
			mapGlobalDispatchToProps
		)(SegmentItemComponent)
	)
);
