import {
	PUBLIC_PRELOADER_ACTION,
	PublicPreloadRequestedAction,
	SECURE_PRELOADER_ACTION,
	SecurePreloadRequestedAction
} from "modules/preloader/types";
import ClustersAPI from "modules/api/ClustersAPI";
import { clusterListFetchSucceeded } from "components/management/cluster/actions";
import {
	publicPreloadFailed,
	publicPreloadSucceeded,
	securePreloadFailed,
	securePreloadSucceeded
} from "modules/preloader/actions";
import Config from "modules/config/Config";
import Axios from "axios";
import SupportMatrixApi from "modules/api/SupportMatrixApi";
import EnvAPI from "modules/api/EnvAPI";
import NodesAPI from "modules/api/NodesAPI";
import HostsAPI from "modules/api/HostsAPI";
import { hostListFetchSucceeded } from "components/management/host/actions";
import { nodeListFetchSucceeded } from "components/management/node/actions";
import DashboardsApi from "modules/api/DashboardsApi";
import UsersApi from "modules/api/UsersApi";
import { takeLatest } from "redux-saga/effects";
import { call, put } from "typed-redux-saga";

function* publicPreload(action: PublicPreloadRequestedAction) {
	try {
		const jwtPublicKeyResponse = yield* call(
			EnvAPI.fetchPublicKey,
			`JWT_PUBLIC_KEY`
		);

		console.log("jwtPublicKeyResponse", jwtPublicKeyResponse);

		if (!jwtPublicKeyResponse.data.value)
			publicPreloadFailed("JWT_PUBLIC_KEY is missing.");

		Config.getInstance().jtw_public_token = jwtPublicKeyResponse.data.value;

		try {
			// get gui version from gui-version.txt file
			const guiVersion = yield* call(Axios.get, `/app/gui-version.txt`) as any;
			guiVersion.data && (Config.getInstance().gui_version = guiVersion.data);
		} catch (e) {
			console.error(e);
			console.warn("gui-version.txt not found.");
		}

		try {
			// get gmd version from version.txt file
			const gmdVersion = yield* call(Axios.get, `/app/version.txt`) as any;
			gmdVersion.data && (Config.getInstance().gmd_version = gmdVersion.data);
		} catch (e) {
			console.error(e);
			console.warn("version.txt not found.");
		}

		// try {
		// 	// get public version from /version endpoint
		// 	const publicVersion = yield Axios.get(
		// 		`${AppEnvironment.getApiAddress()}/${AppEnvironment.getApiVersion()}/version`
		// 	);
		// 	console.log("Running version:", publicVersion.data.value);
		// 	publicVersion.data &&
		// 		(Config.getInstance().public_version = publicVersion.data.value);
		// } catch (e) {
		// 	console.warn("/version error:", e);
		// }

		yield put(publicPreloadSucceeded());
	} catch (e: any) {
		console.error(e);
		yield put(publicPreloadFailed(e.message));
	}
}

function* securePreload(action: SecurePreloadRequestedAction) {
	console.log("secure preload");

	try {
		const currentUser = yield* call(UsersApi.getMe);
		Config.getInstance().currentUserID = currentUser.id;
	} catch (e) {
		console.error("User not logged in");
		// history.push("/login");
	}

	try {
		const clusterList = yield* call(ClustersAPI.fetchList);
		console.log("saga cluster list fetched", clusterList);
		yield put(clusterListFetchSucceeded(clusterList));

		let clusterIndex = 0;
		let cluster = clusterList[clusterIndex];
		while (cluster) {
			const clusterID = cluster.id;
			if (!clusterID) break;

			const hostList = yield* call(HostsAPI.fetchList, clusterID);
			yield put(hostListFetchSucceeded(clusterID, hostList));

			const nodeList = yield* call(NodesAPI.fetchList, clusterID);
			yield put(nodeListFetchSucceeded(clusterID, nodeList));
			cluster = clusterList[++clusterIndex];
		}

		// yield put(userListFetchRequested());
		//
		// yield put(jobListFetchRequested());

		yield call(DashboardsApi.fetchList);

		Config.getInstance().support_matrix = yield* call(
			SupportMatrixApi.fetchSupportMatrix
		);

		const influxUrlFetchResponse = yield* call(EnvAPI.fetchKey, "INFLUXDB_URL");
		console.log("influxUrlFetchResponse", influxUrlFetchResponse);

		const fullUrl = influxUrlFetchResponse.data?.value;

		try {
			const influxUrl = new URL(fullUrl);

			// console.warn(r"influxUrl", fullUrl, influxUrl);

			Config.getInstance().influx_protocol =
				influxUrl.protocol === "https:" ? "https" : "http";
			Config.getInstance().influx_host = influxUrl.hostname;
			Config.getInstance().influx_username = influxUrl.username;
			Config.getInstance().influx_password = influxUrl.password;
			Config.getInstance().influx_port = parseInt(influxUrl.port);
			Config.getInstance().influx_db = influxUrl.pathname.replace("/", "");
		} catch (e) {
			console.warn(
				`Failed to parse InfluxDB Url: ${fullUrl}
				Expected url format: http://username:password@url/[dbname]
				The application will not be able to load logs.`
			);
		}

		console.log("Secure preload succeeded!");
		yield put(securePreloadSucceeded());
	} catch (e: any) {
		console.error(e);
		yield put(securePreloadFailed(e.message));
	}
}

export default function* PreloaderSideEffects() {
	yield takeLatest(PUBLIC_PRELOADER_ACTION.PRELOAD_REQUESTED, publicPreload);
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD_REQUESTED, securePreload);
}

// function* rootSaga () {
// 	yield [
// 		fork(saga1), // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
// 		fork(saga2),
// 	];
// }
