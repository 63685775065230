import {
	DEPLOYMENT_STATUS,
	WSREP_MEMBER_STATE
} from "components/management/cluster/types";

// ###### API V2 MODELS #####
export interface NodeSharedConfig {
	dbEngine: NODE_DB_ENGINE;
	monitoringPassword?: Readonly<string>;
	rootPassword?: Readonly<string>;
	userConfig?: string;
	settings: {
		logs: {
			generalLog: {
				enabled: boolean;
				path?: string;
			};
		};
	};
}

// ###########################

export interface Node extends NodeSharedConfig {
	id?: number;
	unmanaged?: boolean;
	name: string;
	clusterID: number;
	hostID: number;
	deploymentStatus?: string;
	createdAt?: string;
	createdBy?: string;
}

export interface SerializedNode {
	attributes: Node;
	id?: string;
	type: "nodes";
}

export interface NodeDefaults {
	dbEngine: NODE_DB_ENGINE;
	rootPassword?: string;
	userConfig?: string;
	unmanaged?: boolean;
}

export enum NODE_DB_ENGINE {
	MARIADB_10_3 = "mariadb:10.3",
	MARIADB_10_4 = "mariadb:10.4",
	MARIADB_10_5 = "mariadb:10.5",
	MYSQL_5_7 = "mysql:5.7",
	MYSQL_8_0 = "mysql:8.0"
}

export enum DB_STATE {
	UP = 100,
	DOWN = 99,
	UNKNOWN = -1
}

export type NODE_STATE = WSREP_MEMBER_STATE | DB_STATE | DEPLOYMENT_STATUS;

export enum NODE_ACTION {
	LIST_FETCH_REQUESTED = "NODE/LIST_FETCH_REQUESTED",
	LIST_FETCH_SUCCEEDED = "NODE/LIST_FETCH_SUCCEEDED",
	CREATE_REQUESTED = "NODE/CREATE_REQUESTED",
	CREATE_SUCCEEDED = "NODE/CREATE_SUCCEEDED"
}

export interface NodeListFetchRequestedAction {
	type: NODE_ACTION.LIST_FETCH_REQUESTED;
	payload: {
		clusterID: number;
	};
}

export interface NodeListFetchSucceededAction {
	type: NODE_ACTION.LIST_FETCH_SUCCEEDED;
	payload: {
		clusterID: number;
		nodeList: Node[];
	};
}

export interface NodeCreateRequestedAction {
	type: NODE_ACTION.CREATE_REQUESTED;
	payload: {
		node: Node;
	};
}

export interface NodeCreateSucceededAction {
	type: NODE_ACTION.CREATE_SUCCEEDED;
	payload: {
		node: Node;
	};
}

export type NodeAction =
	| NodeListFetchRequestedAction
	| NodeListFetchSucceededAction
	| NodeCreateRequestedAction
	| NodeCreateSucceededAction;
