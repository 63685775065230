import {
	Avatar,
	Box,
	CardHeader,
	Divider,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Typography,
	WithTheme
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { DeleteForever, MoreVert } from "@material-ui/icons";
import { AppState } from "AppState";
import { Host, HOST_STATE } from "components/management/host/types";
import HostsAPI from "modules/api/HostsAPI";
import { showSnackbar } from "components/sharedComponents/snackbar/actionCreators";
import {
	SNACKBAR_TYPE,
	SnackbarActionPayload
} from "components/sharedComponents/snackbar/types";
import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { styles } from "./styles";
import { AxiosError, AxiosResponse } from "axios";
import Menu from "@material-ui/core/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import { Server } from "mdi-material-ui";
import LogsApi from "modules/api/LogsApi";
import { createSelector } from "reselect";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { BlinkingBadge } from "components/sharedComponents/BlinkingBadge/BlinkingBadge";
import { hostListFetchRequested } from "components/management/host/actions";
import JobsService from "modules/jobs/jobsService";
import HostStateComponent from "components/sharedComponents/hostState/HostStateComponent";
import HostUtils from "components/management/host/utils";
import JobListComponent from "components/management/node/jobs/JobListComponent";
import HostLogTable from "components/sharedComponents/logViewer/HostLogTableComponent";
import { GMDialogService } from "components/sharedComponents/dialog/DialogService";
import { Cluster } from "../cluster/types";
import StrayHostFormComponent from "./strayHostForm/StrayHostFormComponent";

// component local state interface
interface LocalState {
	host: Host;
	activeTab: number;
	logs: any[];
	anchorEl: any;
}

interface LocalProps {}

// PROPS
interface ReduxStateProps {
	host: Host;
	cluster: Cluster;
	hasRunningJobs: boolean;
}

interface ReduxDispatchProps {
	showSnackbar: (snackbar: SnackbarActionPayload) => void;
	reloadHosts: (clusterID: number) => void;
}

type Props = LocalProps &
	ReduxStateProps &
	ReduxDispatchProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

interface Snapshot {
	hasHostChanged: boolean;
}

// COMPONENT
class HostManagerComponent extends React.Component<Props, LocalState> {
	_isMounted = false;

	_cancelRequest: any;
	_scheduledFetch: any;

	constructor(props: Props) {
		super(props);

		// console.log("props", props);

		if (props.host) {
			this.state = {
				host: props.host,
				activeTab: 0,
				logs: [],
				anchorEl: undefined
			};
			this.getLogs();
		} else {
			this.props.showSnackbar({
				msg: `Error loading host data`,
				snackbarType: SNACKBAR_TYPE.ERROR
			});
		}
	}

	getLogs = () => {
		this.props.host &&
			LogsApi.fetchHostLog(this.props.host).then((response: AxiosResponse) => {
				console.log("deployment logs", response.data);

				const splitLogs = response.data.data.attributes.contents.split("\n");
				// console.log("split logs", splitLogs);

				this.setState((state: LocalState) => ({
					...state,
					logs: splitLogs.reduce((result: any, row: string) => {
						// console.log("raw line", row);

						try {
							const line = JSON.parse(row);

							// console.log("line", line);

							result.push({
								msg: `${line.time} | ${line.msg}`
							});
						} catch (e) {
							// console.warn("Parsing log line failed", e);
						}

						return result;
					}, [])
				}));
			});
	};

	getSnapshotBeforeUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>
	): any | null {
		return {
			hasHostChanged:
				prevProps.match.params.hostID !== this.props.match.params.hostID
		};
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: Snapshot
	): void {
		if (snapshot && snapshot.hasHostChanged) {
			const { host } = this.props;

			if (host) {
				this.setState(() => ({
					host,
					logs: [],
					anchorEl: undefined
				}));
				this.getLogs();
				this.clearAsyncRequests();
			} else {
				this.props.showSnackbar({
					msg: `Error loading data.`
				});
			}
		}
	}

	componentDidMount(): void {
		this._isMounted = true;
	}

	componentWillUnmount(): void {
		this._isMounted = false;

		this.clearAsyncRequests();
	}

	clearAsyncRequests(): void {
		if (this._cancelRequest) this._cancelRequest();
		if (this._scheduledFetch) clearTimeout(this._scheduledFetch);
	}

	onDeleteClick = (host: Host, force?: boolean): void => {
		const message = force
			? `Host will be deleted if possible. If deletion fails, the host will still be removed from Galera Manager.`
			: `Host will be deleted.`;

		GMDialogService.showConfirm({
			title: force
				? `Force delete host ${this.state.host.name}?`
				: `Delete host ${this.state.host.name}?`,
			message,
			confirmText: force ? "Force delete" : "Delete",
			declineText: "Cancel",
			destructiveConfirm: true
		}).then(
			() => {
				HostsAPI.delete(host, force).then(
					(job: Job) => {
						console.log("host delete job", job);

						JobsService.monitorJob(job.id).then(
							() => {
								this.props.showSnackbar({
									msg: `Host '${host.name}' successfully deleted`
								});

								// redirect to cluster if host still open
								if (
									this.props.location.pathname.endsWith(
										`/clusters/${host.clusterID}/hosts/${host.id}`
									)
								) {
									this.props.history.push(`/clusters/${host.clusterID}`);
								}

								this.props.reloadHosts(host.clusterID);
							},
							(err: any) => {
								console.error("Host deletion job monitor error:", err);

								this.props.showSnackbar({
									msg: `Host '${host.name}' deletion job error.`,
									snackbarType: SNACKBAR_TYPE.ERROR
								});
							}
						);
					},
					(err: AxiosError) => {
						console.error("Error deleting host:", err);

						this.props.showSnackbar({
							msg: `Host '${host.name}' could not be deleted`,
							snackbarType: SNACKBAR_TYPE.ERROR
						});
					}
				);

				this.props.showSnackbar({
					msg: `Host '${host.name}' is being deleted`
				});
			},
			() => {}
		);

		this.setState({
			anchorEl: null
		});
	};

	onMoreClick = (event: any) => {
		// isExpanded menu
		this.setState({ ...this.state, anchorEl: event.currentTarget });
	};

	onMenuClose = () => {
		this.setState({ ...this.state, anchorEl: null });
	};

	render(): React.ReactNode {
		const { classes, theme, hasRunningJobs, cluster } = this.props;

		if (!this.state) return false;

		const { host, activeTab, anchorEl } = this.state;

		const hostState: HOST_STATE = HostUtils.getHostState(host.deploymentStatus);

		return (
			<Card className={classes.card}>
				<CardHeader
					avatar={
						<Avatar
							style={{ backgroundColor: theme.palette.primary.main }}
							aria-label="Host"
						>
							<Server />
						</Avatar>
					}
					action={
						<Grid container direction="row" alignItems="center">
							<Grid item>{<HostStateComponent hostState={hostState} />}</Grid>
							<Grid item>
								<IconButton
									data-cy="host-manager-more-button"
									onClick={this.onMoreClick}
								>
									<MoreVert />
								</IconButton>
								<Menu
									id="long-menu"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={this.onMenuClose}
								>
									<MenuItem
										data-cy="delete-host"
										onClick={() => {
											this.onDeleteClick(host);
										}}
									>
										<ListItemIcon>
											<DeleteIcon />
										</ListItemIcon>
										<ListItemText primary="Delete host" />
									</MenuItem>
									<MenuItem
										data-cy="delete-host-force"
										onClick={() => {
											this.onDeleteClick(host, true);
										}}
									>
										<ListItemIcon>
											<DeleteForever />
										</ListItemIcon>
										<ListItemText primary="Force delete host" />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					}
					title={host.name}
					subheader={host.system}
				/>
				<Divider style={{ margin: 0 }} />
				<Tabs
					className={classes.tabs}
					value={activeTab}
					onChange={(event: ChangeEvent<{}>, newValue: any) => {
						this.setState((state: LocalState) => ({
							...state,
							activeTab: newValue
						}));
					}}
					variant="fullWidth"
				>
					<Tab className={classes.tab} label={<Typography>Logs</Typography>} />
					<Tab
						className={classes.tab}
						label={
							<Typography data-cy="configuration-tab">Configuration</Typography>
						}
					/>
					<Tab
						className={classes.tab}
						label={
							hasRunningJobs ? (
								<BlinkingBadge variant="dot" color="primary">
									<Typography>Jobs</Typography>
								</BlinkingBadge>
							) : (
								<Typography>Jobs</Typography>
							)
						}
					/>
				</Tabs>
				<CardContent>
					{(activeTab === 0 && (
						<Box style={{ height: 500 }}>
							<HostLogTable
								rowCount={this.state.logs.length}
								rowGetter={({ index }: any) => this.state.logs[index]}
								columns={[
									{
										width: 200,
										flexGrow: 1,
										label: "",
										dataKey: "msg"
									}
								]}
							/>
						</Box>
					)) ||
						(activeTab === 1 && (
							<StrayHostFormComponent cluster={cluster} host={host} />
						)) ||
						(activeTab === 2 && <JobListComponent host={host} />)}
				</CardContent>
			</Card>
		);
	}
}

// selectors
const makeHostSelector = () =>
	createSelector(
		(state: AppState) => state.hostList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(state: AppState, props: Props) => props.match.params.hostID,
		(hostMap: Map<number, Host[]>, clusterID: number, hostID: number): Host => {
			const hostList = hostMap.get(clusterID) || [];

			const host = hostList.find((host: Host) => host.id === hostID);
			if (!host) throw Error(`Could not find host`);

			return host;
		}
	);

const makeClusterSelector = () =>
	createSelector(
		(state: AppState) => state.clusterList,
		(state: AppState, props: Props) => props.match.params.clusterID,
		(clusterList: Cluster[], clusterID: number): Cluster => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === clusterID
			);

			if (!cluster) throw Error(`Could not find cluster`);

			return cluster;
		}
	);

const makeHasRunningJobs = () =>
	createSelector(
		(state: AppState) => state.jobMonitor.runningJobList,
		(state: AppState, props: Props) => props.match.params.hostID,
		(jobList: Job[], hostID: number) =>
			// todo: was using host name for filtering jobs
			jobList.some(
				(job: Job) =>
					job.meta.host_id === hostID &&
					job.executionInfo.status === JOB_STATUS.RUNNING
			)
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: Props) => {
	const hostSelector = makeHostSelector();
	const clusterSelector = makeClusterSelector();
	const hasRunningJobsSelector = makeHasRunningJobs();

	return {
		host: hostSelector(state, props),
		cluster: clusterSelector(state, props),
		hasRunningJobs: hasRunningJobsSelector(state, props)
	};
};

const mapGlobalDispatchToProps = (dispatch: any) => {
	return {
		reloadHosts: (clusterID: number) => {
			dispatch(hostListFetchRequested(clusterID));
		},
		showSnackbar: (snackbar: SnackbarActionPayload) => {
			dispatch(showSnackbar(snackbar));
		}
	};
};

export default connect(
	mapGlobalStateToProps,
	mapGlobalDispatchToProps
)(withStyles(styles, { withTheme: true })(HostManagerComponent));
