// export interface ResultItem {
// 	metric: Metric;
// 	value: [number, string];
// }
//
// export interface Metric {
// 	__name__: string;
// 	gmd_cluster: string;
// 	gmd_db_engine: string;
// 	gmd_db_segment: string;
// 	gmd_host: string;
// 	gmd_host_system: string;
// 	gmd_node: string;
// 	instance: string;
// 	job: string;
// }

export interface NodeMetric {
	time: Date;
	cluster: string;
	node: string;
	value: number;
}

export interface ClusterMetric {
	time: Date;
	cluster: string;
	value: number;
}

export interface WsrepLocalStateMetric extends NodeMetric {
	type: METRIC.WSREP_LOCAL_STATE;
}

export interface WsrepLastCommittedMetric extends NodeMetric {
	type: METRIC.WSREP_LAST_COMMITTED;
}

export enum METRIC {
	WSREP_LOCAL_STATE = "wsrep_local_state",
	WSREP_LAST_COMMITTED = "wsrep_last_committed",
	MYSQL_UP = "mysql_up"
}

// redux
// todo identifications are now numbers (clusterID, nodeID)
export interface MetricsStoreState {
	wsrepLocalStateMetrics: Record<string, WsrepLocalStateMetric>;
	wsrepLastCommittedMetrics: Record<string, WsrepLastCommittedMetric>;
	joinedNodes: Record<string, number>;
}

export enum METRICS_ACTION {
	WSREP_LOCAL_STATE_METRICS_FETCH_REQUESTED = "METRICS_ACTION/WSREP_LOCAL_STATE_METRICS_FETCH_REQUESTED",
	WSREP_LOCAL_STATE_METRICS_FETCH_SUCCEEDED = "METRICS_ACTION/WSREP_LOCAL_STATE_METRICS_FETCH_SUCCEEDED",
	WSREP_LAST_COMMITTED_METRICS_FETCH_REQUESTED = "METRICS_ACTION/WSREP_LAST_COMMITTED_METRICS_FETCH_REQUESTED",
	WSREP_LAST_COMMITTED_METRICS_FETCH_SUCCEEDED = "METRICS_ACTION/WSREP_LAST_COMMITTED_METRICS_FETCH_SUCCEEDED"
}

export interface WsrepLocalStateMetricsFetchRequestedAction {
	type: METRICS_ACTION.WSREP_LOCAL_STATE_METRICS_FETCH_REQUESTED;
}

export interface WsrepLocalStateMetricsFetchSucceededAction {
	type: METRICS_ACTION.WSREP_LOCAL_STATE_METRICS_FETCH_SUCCEEDED;
	payload: {
		metrics: WsrepLocalStateMetric[];
	};
}

export interface LastCommittedMetricsFetchRequestedAction {
	type: METRICS_ACTION.WSREP_LAST_COMMITTED_METRICS_FETCH_REQUESTED;
}

export interface LastCommittedMetricsFetchSucceededAction {
	type: METRICS_ACTION.WSREP_LAST_COMMITTED_METRICS_FETCH_SUCCEEDED;
	payload: {
		metrics: Record<
			string,
			WsrepLastCommittedMetric>;
	};
}

export type MetricsAction =
	| LastCommittedMetricsFetchRequestedAction
	| LastCommittedMetricsFetchSucceededAction
	| WsrepLocalStateMetricsFetchRequestedAction
	| WsrepLocalStateMetricsFetchSucceededAction;
