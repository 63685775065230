import { Theme, createStyles } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		stepperRoot: {
			padding: "0 0 20px 0"
		},
		buttonLoader: {
			marginRight: "10px"
		},
		paperContainer: {
			cursor: "pointer",
			"&:hover": {
				backgroundColor: "#f6f6f6"
			},
			"&:active": {
				backgroundColor: "#f0f0f0"
			}
		},
		typeSelectionPaper: {
			padding: "10px"
			// backgroundColor: "#f0f0f0"
		},
		monitorBox: {
			width: "120px",
			height: "95px",
			textAlign: "center",
			border: "2px solid",
			borderColor: "#646464"
		},
		monitorIcon: {
			color: "#646464",
			fontSize: "4.5em",
			padding: "10px 10px 0 10px"
		},
		monitorText: {
			color: "#646464",
			fontSize: "0.8em"
		},
		manageBox: {
			width: "120px",
			height: "95px",
			textAlign: "center",
			border: "2px solid",
			borderColor: theme.palette.primary.main
		},
		manageIcon: {
			color: theme.palette.primary.main,
			fontSize: "4.5em",
			padding: "10px 10px 0 10px"
		},
		manageText: {
			color: theme.palette.primary.dark,
			fontSize: "0.8em"
		},
		publicKey: {
			fontSize: "0.9em",
			wordBreak: "break-all",
			color: "white",
			backgroundColor: "black",
			fontFamily: "monospace",
			padding: theme.spacing(1)
		},
		copyKeyIcon: {
			color: 'white'
		}
	});
