import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		flexContainer: {
			display: "flex",
			alignItems: "center",
			boxSizing: "border-box"
		},
		tableRow: {
			cursor: "pointer"
		},
		tableRowHover: {
			"&:hover": {
				backgroundColor: theme.palette.grey[200]
			}
		},
		tableCell: {
			flex: 1,
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			overflow: "hidden",
			fontSize: "small",
			fontFamily: "monospace"
		},
		noClick: {
			cursor: "initial"
		},

		logContent: {
			backgroundColor: "black",
			color: "white",
			overflow: "auto",
			padding: 5
		},
		logMessagesTypography: {
			fontSize: "0.9em",
			fontFamily: "monospace",
			whiteSpace: "break-spaces"
		},
		galeraManagerLogMessage: {
			color: theme.palette.primary.main
		},
		logInfoTypography: {
			fontFamily: "monospace"
		},
		warningLog: {
			color: theme.palette.warning.main
		},
		errorLog: {
			color: theme.palette.error.main
		},
		toolbar: {
			marginBottom: 0,
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(2)
		},
		formControl: {
			marginTop: 0,
			marginBottom: 0,
			marginRight: theme.spacing(2)
		},
		selectOption: {
			minHeight: 25
		},
		expansionPanelExpanded: {
			marginBottom: "0!important"
		}
	});
