import { Job } from "../types";

export interface JobMonitorState {
	jobsToMonitor: number[];
	monitoredJobList: Job[];
	runningJobList: Job[];
}

export enum JOB_MONITOR_ACTION {
	JOB_MONITOR_START = "JOB_MONITOR/START",
	JOB_MONITOR_FETCH_RUNNING_SUCCEEDED = "JOB_MONITOR/FETCH_RUNNING_SUCCEEDED",
	JOB_MONITOR_FETCH_MONITORED_SUCCEEDED = "JOB_MONITOR/FETCH_MONITORED_SUCCEEDED",
	JOB_MONITOR_STOP = "JOB_MONITOR/STOP"
}

export interface JobMonitorStartAction {
	type: JOB_MONITOR_ACTION.JOB_MONITOR_START;
	payload: {
		jobId: number;
	};
}

export interface JobMonitorFetchSucceededAction {
	type:
		| JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_RUNNING_SUCCEEDED
		| JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_MONITORED_SUCCEEDED;
	payload: {
		jobList: Job[];
	};
}

export interface JobMonitorStopAction {
	type: JOB_MONITOR_ACTION.JOB_MONITOR_STOP;
	payload: {
		jobId: number;
	};
}

export type JobMonitorAction =
	| JobMonitorStartAction
	| JobMonitorFetchSucceededAction
	| JobMonitorStopAction;
