import React from "react";
import { Node } from "components/management/node/types";
import { Job } from "modules/jobs/types";
import {
	Grid,
	Icon,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TablePagination,
	Typography
} from "@material-ui/core";
import { Console } from "mdi-material-ui";
import moment from "moment";
import JobStatusComponent from "components/management/node/jobs/jobStatus/jobStatusComponent";
import { DoneAll } from "@material-ui/icons";
import { Host } from "components/management/host/types";
import { Cluster } from "components/management/cluster/types";
import JobsApi from "../../../../modules/api/JobsApi";
import { Skeleton } from "@material-ui/lab";
import axios, { AxiosError, CancelTokenSource } from "axios";

interface LocalState {
	pageSize: number;
	currentPage: number;
	totalCount: number;
	jobs: Job[];
	isLoading: boolean;
	showSkeleton: boolean;
	errorMessage?: string;
}

// pass node and/or host for which you want this component to display jobs
interface LocalProps {
	cluster?: Cluster;
	node?: Node;
	host?: Host;
}

type Props = LocalProps;

class jobsComponent extends React.Component<Props, LocalState> {
	isComponentMounted: boolean = false;
	cancelTokenSource?: CancelTokenSource;

	constructor(props: Props) {
		super(props);

		console.log("job list constructor");

		this.state = {
			pageSize: 10,
			currentPage: 1,
			totalCount: 0,
			jobs: [],
			isLoading: true,
			showSkeleton: true
		};

		this.loadJobs();
	}

	componentDidMount() {
		this.isComponentMounted = true;
	}

	componentWillUnmount() {
		this.isComponentMounted = false;
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	) {
		if (
			prevProps.cluster !== this.props.cluster ||
			prevProps.host !== this.props.host ||
			prevProps.node !== this.props.node
		) {
			this.loadJobs(1);
			this.setState({ currentPage: 1 });
		}
	}

	loadJobs = (newCurrentPage?: number, newPageSize?: number) => {
		const { cluster, node, host } = this.props;
		const { currentPage, pageSize } = this.state;

		if (this.cancelTokenSource) {
			console.log("another request in progress. cancelling...");
			this.cancelTokenSource.cancel("cancelled");
		}
		this.cancelTokenSource = axios.CancelToken.source();

		this.isComponentMounted && this.setState({ isLoading: true });

		setTimeout(() => {
			if (this.state.isLoading) {
				this.setState({ showSkeleton: true });
			}
		}, 500);

		if (cluster?.id || node?.clusterID || host?.clusterID) {
			JobsApi.fetchPaginatedList(
				newCurrentPage || currentPage,
				newPageSize || pageSize,
				cluster?.id || node?.clusterID || host?.clusterID || -1,
				node?.id,
				host?.id,
				this.cancelTokenSource.token
			)
				.then(({ list, totalCount }) => {
					console.log("job list", list);
					delete this.cancelTokenSource;
					this.setState({
						jobs: list,
						totalCount,
						isLoading: false,
						showSkeleton: false,
						errorMessage: undefined
					});
				})
				.catch((error: AxiosError) => {
					console.log("error", error, error.message);
					if (error.message !== "cancelled") {
						this.setState({
							isLoading: false,
							showSkeleton: false,
							errorMessage: error.message
						});
					}
				});
		}
	};

	render():
		| React.ReactElement<any, string | React.JSXElementConstructor<any>>
		| string
		| number
		| {}
		| React.ReactNodeArray
		| React.ReactPortal
		| boolean
		| null
		| undefined {
		const { jobs, currentPage, pageSize, showSkeleton, totalCount } =
			this.state;

		const handleChangePage = (
			event: React.MouseEvent<HTMLButtonElement> | null,
			newPage: number
		) => {
			this.setState({ currentPage: newPage + 1 });
			this.loadJobs(newPage + 1);
		};

		const handleChangeRowsPerPage = (
			event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		) => {
			const pageSize = parseInt(event.target.value, 10);
			this.setState({
				currentPage: 1,
				pageSize
			});
			this.loadJobs(1, pageSize);
		};

		return (
			<>
				{showSkeleton ? (
					<List>
						{jobs.map((job: Job) => (
							<ListItem button key={job.id}>
								<ListItemAvatar>
									<Skeleton variant="circle" height="30px" width="30px" />
								</ListItemAvatar>
								<ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
							</ListItem>
						))}
						{jobs.length === 0 && (
							<ListItem button key="no-jobs">
								<ListItemAvatar>
									<Skeleton variant="circle" height="30px" width="30px" />
								</ListItemAvatar>
								<ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
							</ListItem>
						)}
					</List>
				) : jobs.length > 0 ? (
					<List>
						{jobs.map((job: Job) => (
							<ListItem button key={job.id}>
								<ListItemAvatar>
									<Console />
								</ListItemAvatar>
								<ListItemText
									primary={job.description}
									secondary={`Started on ${moment(job.createdAt).format(
										"LLL"
									)} (${moment(job.createdAt).fromNow()})${
										job.executionInfo.details
											? ", response: " + job.executionInfo.details
											: ""
									}`}
								/>
								<JobStatusComponent jobStatus={job.executionInfo.status} />
							</ListItem>
						))}
					</List>
				) : (
					<>
						<Grid container direction="row" spacing={2}>
							<Grid item>
								<Icon>
									<DoneAll />
								</Icon>
							</Grid>
							<Grid item>
								<Typography variant="subtitle2">No jobs</Typography>
							</Grid>
						</Grid>
					</>
				)}
				<TablePagination
					component="div"
					count={totalCount}
					page={currentPage - 1}
					onPageChange={handleChangePage}
					rowsPerPage={pageSize}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</>
		);
	}
}

export default jobsComponent;
