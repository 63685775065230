import React, { ChangeEvent } from "react";
import { withStyles, WithStyles, WithTheme } from "@material-ui/core/styles";
import { styles } from "./styles";
import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography
} from "@material-ui/core";
import HiddenJs from "@material-ui/core/Hidden/HiddenJs";
import TimeSeriesChartComponent from "components/monitoring/charts/timeSeries/TimeSeriesChartComponent";
import { Cluster } from "components/management/cluster/types";
import { Node } from "components/management/node/types";
import Autocomplete from "components/monitoring/dashboard/AddElement/Autocomplete/Autocomplete";
import { AGGREGATION, Chart } from "components/monitoring/dashboard/types";
import { ChartMetric } from "components/monitoring/charts/const";

interface LocalState {
	chart: Chart;
}

interface LocalProps {
	isOpen: boolean;
	onCancelClick: () => void;
	onAddClick: (chart: Chart) => void;
	cluster: Cluster;
	node?: Node;
}

type Props = LocalProps & WithStyles<typeof styles> & WithTheme;

class AddElement extends React.PureComponent<Props, LocalState> {
	defaultState: LocalState = {
		chart: {
			id: "", // overridden on adding chart to dashboard
			position: 0, // overridden on adding chart to dashboard
			resolution: "", // overridden on adding chart to dashboard
			title: "",
			metric: {
				name: "",
				table: ""
			},
			aggregation: AGGREGATION.RAW,
			dataScaling: 1
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = this.defaultState;
	}

	render() {
		const { isOpen, cluster, theme } = this.props;
		const { chart } = this.state;

		const renderDialog = () => (
			<>
				{/*Mobile*/}
				<HiddenJs smUp>
					<Dialog
						open={isOpen}
						onClose={(event: Event, reason: string) => {
							if (reason === 'escapeKeyDown') {
								this.props.onCancelClick();
								this.setState(this.defaultState);
							}
						}}
						fullWidth={true}
						fullScreen={true}
						aria-labelledby="form-dialog-title"
					>
						{isOpen && dialogContentRender()}
					</Dialog>
				</HiddenJs>

				{/*Desktop*/}
				<HiddenJs xsDown>
					<Dialog
						open={isOpen}
						onClose={(event: Event, reason: string) => {
							if (reason === 'escapeKeyDown') {
								this.props.onCancelClick();
								this.setState(this.defaultState);
							}
						}}
						fullWidth={true}
						maxWidth={"sm"}
						aria-labelledby="form-dialog-title"
					>
						{isOpen && dialogContentRender()}
					</Dialog>
				</HiddenJs>
			</>
		);

		const dialogContentRender = () => (
			<>
				<DialogTitle id="form-dialog-title">Add chart</DialogTitle>
				<DialogContent>
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<TextField
								label={"Chart title"}
								value={chart.title}
								onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
									event.persist();
									console.log("onTitleChange", event);

									this.setState((state: LocalState) => {
										return {
											...state,
											chart: {
												...state.chart,
												title: event.target.value as string
											}
										};
									});
								}}
							/>
						</Grid>
						<Grid item style={{ width: "100%" }}>
							<Autocomplete
								clusterName={cluster.name}
								onChanged={(metric: ChartMetric) => {
									console.log("onChanged", metric);
									this.setState((state: LocalState) => ({
										chart: {
											...state.chart,
											metric
										}
									}));
								}}
							/>
						</Grid>
						<Grid item>
							<ButtonGroup
								size="small"
								aria-label="small outlined button group"
							>
								<Button
									style={
										chart.aggregation === AGGREGATION.RAW
											? { color: theme.palette.primary.main }
											: {}
									}
									onClick={() => {
										this.setState((state: LocalState) => ({
											chart: {
												...state.chart,
												aggregation: AGGREGATION.RAW
											}
										}));
									}}
								>
									Raw
								</Button>
								<Button
									style={
										chart.aggregation === AGGREGATION.DIFFERENTIAL
											? { color: theme.palette.primary.main }
											: {}
									}
									onClick={() => {
										this.setState((state: LocalState) => ({
											chart: {
												...state.chart,
												aggregation: AGGREGATION.DIFFERENTIAL
											}
										}));
									}}
								>
									Differential
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item container direction="row" spacing={1}>
							<Grid item>
								<TextField
									label={"Measurement unit"}
									value={chart.unit}
									onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
										event.persist();
										console.log("onUnitChange", event);

										this.setState((state: LocalState) => {
											return {
												...state,
												chart: {
													...state.chart,
													unit: event.target.value as string
												}
											};
										});
									}}
								/>
							</Grid>
							<Grid item>
								<TextField
									type="number"
									label={"Data scaling"}
									value={chart.dataScaling}
									onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
										event.persist();
										console.log("onUnitChange", event);

										this.setState((state: LocalState) => {
											return {
												...state,
												chart: {
													...state.chart,
													dataScaling: parseFloat(event.target.value) || 1
												}
											};
										});
									}}
								/>
							</Grid>

							<Grid item>
								<TextField
									type="number"
									label={"Y axis max"}
									value={chart.yAxisMax}
									onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
										event.persist();
										console.log("onYAxisMaxChange", event);

										this.setState((state: LocalState) => {
											return {
												...state,
												chart: {
													...state.chart,
													yAxisMax: parseFloat(event.target.value)
												}
											};
										});
									}}
								/>
							</Grid>
						</Grid>

						<Grid item>
							<Typography>Preview</Typography>
						</Grid>

						<Grid item style={{ position: "relative", height: 250 }}>
							<TimeSeriesChartComponent
								cluster={this.props.cluster}
								node={this.props.node}
								metric={chart.metric}
								aggregation={chart.aggregation}
								title={chart.title}
								containerWidth={550}
								containerHeight={250}
								disableRender={false}
								unit={chart.unit}
								dataScaling={chart.dataScaling}
								yAxisMax={chart.yAxisMax}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(): void => {
							this.props.onCancelClick();
							this.setState(this.defaultState);
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={(): void => {
							this.props.onAddClick(chart);
							this.setState(this.defaultState);
						}}
					>
						Add
					</Button>
				</DialogActions>
			</>
		);

		return <>{renderDialog()}</>;
	}
}

export default withStyles(styles, { withTheme: true })(AddElement);
