import {
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	withStyles,
	WithStyles,
	WithTheme
} from "@material-ui/core";
import { AppState } from "AppState";
import React from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Database } from "mdi-material-ui";
import {
	Cluster,
	WSREP_MEMBER_STATE
} from "components/management/cluster/types";
import NodeStateComponent from "components/sharedComponents/nodeState/nodeStateComponent";
import { createSelector } from "reselect";
import { Job, JOB_STATUS } from "modules/jobs/types";
import { DB_STATE, Node, NODE_STATE } from "components/management/node/types";
import { BlinkingBadge } from "components/sharedComponents/BlinkingBadge/BlinkingBadge";
import { styles } from "./styles";
import { MetricsStoreState } from "modules/metricsStore/types";

// component local state interface
interface State {
	isSelected: boolean;
}

// PROPS
interface LocalProps {
	node: Node;
}

interface DispatchProps {}

interface ReduxStateProps {
	hasRunningJobs: boolean;
	nodeState: NODE_STATE;
	committedTransactions: number;
}

type Props = LocalProps &
	DispatchProps &
	ReduxStateProps &
	WithStyles<typeof styles> &
	WithTheme &
	RouteComponentProps<any, StaticContext, any>;

// COMPONENT
class NodeItemComponent extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isSelected: false
		};
	}

	handleClick = () => {
		const { clusterID, id: nodeID } = this.props.node;
		this.props.history.push(`/clusters/${clusterID}/nodes/${nodeID}`);
	};

	componentDidUpdate(prevProps: Props) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}

	componentDidMount(): void {
		this.onRouteChanged();
	}

	onRouteChanged() {
		const isSelected = this.props.location.pathname.endsWith(
			`/${this.props.node.clusterID}/nodes/${this.props.node.id}`
		);

		if (isSelected !== this.state.isSelected) {
			this.setState((state: State) => ({
				...state,
				isSelected
			}));
		}
	}

	render(): React.ReactNode {
		const {
			theme,
			hasRunningJobs,
			nodeState,
			node,
			classes,
			committedTransactions
		} = this.props;
		const { isSelected } = this.state;

		const avatar = (
			<Avatar
				className={
					((nodeState === WSREP_MEMBER_STATE.DONOR ||
						nodeState === WSREP_MEMBER_STATE.JOINED ||
						nodeState === WSREP_MEMBER_STATE.JOINER) &&
						classes.pulsating) ||
					undefined
				}
				style={{
					backgroundColor:
						((nodeState === WSREP_MEMBER_STATE.DONOR ||
							nodeState === WSREP_MEMBER_STATE.JOINED ||
							nodeState === WSREP_MEMBER_STATE.SYNCED) &&
							theme.palette.primary.main) ||
						undefined
				}}
			>
				<Database />
			</Avatar>
		);

		return (
			<>
				<ListItem
					data-cy={`node-${node.name}-tree-view-item`}
					selected={isSelected}
					button
					className={classes.doubleNested}
					onClick={this.handleClick}
				>
					<ListItemAvatar>
						{hasRunningJobs ? (
							<BlinkingBadge variant="dot" color="primary">
								{avatar}
							</BlinkingBadge>
						) : (
							avatar
						)}
					</ListItemAvatar>
					<ListItemText
						data-cy="node-item-text"
						primary={
							<Grid container direction="row" spacing={1}>
								<Grid item>
									<Typography variant="body2">{node.name}</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2" color="textSecondary">
										({committedTransactions} tx)
									</Typography>
								</Grid>
							</Grid>
						}
						secondary={node.dbEngine}
					/>
					<NodeStateComponent nodeState={nodeState} />
				</ListItem>
			</>
		);
	}
}

// selectors
const makeHasRunningJobs = () =>
	createSelector(
		(state: AppState) => state.jobMonitor.runningJobList,
		(state: AppState, props: LocalProps) => props.node,
		(jobList: Job[], node: Node) =>
			jobList.some(
				(job: Job) =>
					(job.meta.node_id === node.id || job.meta.host_id === node.hostID) &&
					job.meta.cluster_id === node.clusterID &&
					job.executionInfo.status === JOB_STATUS.RUNNING
			)
	);

const makeNodeStateSelector = () =>
	createSelector(
		(state: AppState) => state.clusterList,
		(state: AppState) => state.metrics,
		(state: AppState, props: LocalProps) => props.node,
		(
			clusterList: Cluster[],
			metricsStore: MetricsStoreState,
			node: Node
		): NODE_STATE => {
			const clusterName = clusterList.find(
				(cluster: Cluster) => cluster.id === node.clusterID
			)?.name;

			if (clusterName) {
				const localStateMetric =
					metricsStore.wsrepLocalStateMetrics[`${clusterName},${node.name}`];
				return localStateMetric?.value || DB_STATE.UNKNOWN;
			} else {
				return DB_STATE.UNKNOWN;
			}
		}
	);

const makeCommittedTransactionsSelector = () =>
	createSelector(
		(state: AppState) => state.metrics,
		(state: AppState) => state.clusterList,
		(state: AppState, props: LocalProps) => props.node,
		(
			metricsRecord: MetricsStoreState,
			clusterList: Cluster[],
			node: Node
		): number => {
			const cluster = clusterList.find(
				(cluster: Cluster) => cluster.id === node.clusterID
			);

			if (cluster) {
				const lastCommitted =
					metricsRecord.wsrepLastCommittedMetrics[
						`${cluster.name},${node.name}`
					];
				return lastCommitted?.value || 0;
			} else {
				throw Error(`Cannot find cluster.`);
			}
		}
	);

// REDUX MAPPINGS
const mapGlobalStateToProps = (state: AppState, props: LocalProps) => {
	const hasRunningJobsSelector = makeHasRunningJobs();
	const nodeStateSelector = makeNodeStateSelector();
	const committedTransactionsSelector = makeCommittedTransactionsSelector();

	return {
		hasRunningJobs: hasRunningJobsSelector(state, props),
		nodeState: nodeStateSelector(state, props),
		committedTransactions: committedTransactionsSelector(state, props)
	};
};

export default withStyles(styles, { withTheme: true })(
	withRouter(connect(mapGlobalStateToProps, {})(NodeItemComponent))
);
