import JobsApi from "modules/api/JobsApi";
import { call, delay, put, select } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { SECURE_PRELOADER_ACTION } from "modules/preloader/types";
import { AppState } from "../../../AppState";
import {
	jobMonitorFetchMonitoredSucceeded,
	jobMonitorFetchRunningSucceeded
} from "./actions";

function* monitorRunningJobList() {
	console.log("Running jobs monitor started");

	while (true) {
		try {
			const runningJobs = yield* call(JobsApi.fetchMonitoredJobList);
			// console.log("running jobs", runningJobs);
			yield put(jobMonitorFetchRunningSucceeded(runningJobs));
		} catch (e: any) {
			console.error("Job list monitor error:", e);
		} finally {
			yield delay(2000);
		}
	}
}

function* monitorJobList() {
	console.log("Jobs monitor started");

	while (true) {
		try {
			const jobsToMonitor: number[] = yield select(monitoredJobsSelector);
			if (jobsToMonitor.length > 0) {
				const monitoredJobs = yield* call(
					JobsApi.fetchMonitoredJobList,
					jobsToMonitor
				);
				console.log("monitored jobs", monitoredJobs);
				yield put(jobMonitorFetchMonitoredSucceeded(monitoredJobs));
			} else {
				console.log("no jobs to monitor");
			}
		} catch (e: any) {
			console.error("Jobs monitor error:", e);
		} finally {
			yield delay(2000);
		}
	}
}

function* JobSideEffects() {
	yield takeLatest(SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED, monitorJobList);
	yield takeLatest(
		SECURE_PRELOADER_ACTION.PRELOAD_SUCCEEDED,
		monitorRunningJobList
	);
}

export const monitoredJobsSelector = (state: AppState): number[] =>
	state.jobMonitor.jobsToMonitor;

export default JobSideEffects;
