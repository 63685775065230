import {
	JOB_MONITOR_ACTION,
	JobMonitorFetchSucceededAction,
	JobMonitorStartAction,
	JobMonitorStopAction
} from "modules/jobs/monitor/types";
import { Job } from "../types";

export const jobMonitorStart = (jobId: number): JobMonitorStartAction => ({
	type: JOB_MONITOR_ACTION.JOB_MONITOR_START,
	payload: {
		jobId
	}
});

export const jobMonitorFetchMonitoredSucceeded = (
	jobList: Job[]
): JobMonitorFetchSucceededAction => ({
	type: JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_MONITORED_SUCCEEDED,
	payload: {
		jobList
	}
});

export const jobMonitorFetchRunningSucceeded = (
	jobList: Job[]
): JobMonitorFetchSucceededAction => ({
	type: JOB_MONITOR_ACTION.JOB_MONITOR_FETCH_RUNNING_SUCCEEDED,
	payload: {
		jobList
	}
});

export const jobMonitorStop = (jobId: number): JobMonitorStopAction => ({
	type: JOB_MONITOR_ACTION.JOB_MONITOR_STOP,
	payload: {
		jobId
	}
});
