import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
	createStyles({
		root: {
			width: "100%"
		},
		publicKey: {
			fontSize: "0.9em",
			wordBreak: "break-all",
			color: "white",
			backgroundColor: "black",
			fontFamily: "monospace",
			padding: theme.spacing(1)
		}
	});
