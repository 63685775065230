import React, { ChangeEvent, FormEvent } from "react";
import {
	CircularProgress,
	FormControl,
	FormHelperText,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip
} from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";
import AWSUtils from "../../../modules/aws/AWSUtils";
import { InstanceTypeInfo, Region } from "@aws-sdk/client-ec2";
import { EC2SpecificSettings } from "../../management/host/types";
import { styles } from "./styles";
import { withStyles, WithStyles } from "@material-ui/core/styles";

interface LocalState {
	formValidation: {
		awsAccessKeyID?: {
			isInvalid: boolean;
			message: string;
		};
		awsSecretAccessKey?: {
			isInvalid: boolean;
			message: string;
		};
		awsEC2Region?: {
			isInvalid: boolean;
			message: string;
		};
		awsEC2InstanceType?: {
			isInvalid: boolean;
			message: string;
		};
		areAwsCredentialsVerified: boolean;
		areAwsCredentialsBeingVerified: boolean;
	};
	availableRegions?: Region[];
	availableInstanceTypes?: InstanceTypeInfo[];
}

interface LocalProps {
	config: EC2SpecificSettings;
	onChange?: (ec2Config: EC2SpecificSettings) => void;
	readOnly?: boolean;
	requirements: {
		minRAM: number;
	};
}

type Props = LocalProps & WithStyles<typeof styles>;

class EC2ConfigComponent extends React.PureComponent<Props, LocalState> {
	ec2CredentialsVerificationTimeout?: ReturnType<typeof setTimeout>;

	constructor(props: Props) {
		super(props);

		let availableRegions: Region[] = [];

		if (props.config.region) {
			availableRegions.push({ RegionName: props.config.region });
		}

		this.state = {
			formValidation: {
				areAwsCredentialsVerified: false,
				areAwsCredentialsBeingVerified: false
			},
			availableRegions
		};
	}

	componentDidMount() {
		if (
			!this.props.readOnly &&
			this.props.config.accessKeyID &&
			this.props.config.secretAccessKey
		) {
			this.fetchEC2Data(
				this.props.config.accessKeyID,
				this.props.config.secretAccessKey
			);
		}
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<LocalState>,
		snapshot?: any
	) {
		if (prevProps.requirements.minRAM !== this.props.requirements.minRAM) {
			// fetch instance type properties
			const selectedInstanceType = this.state.availableInstanceTypes?.find(
				(instanceType: InstanceTypeInfo) =>
					instanceType.InstanceType === this.props.config.instanceType
			);

			// console.log("selectedInstanceType", selectedInstanceType);

			if (
				selectedInstanceType &&
				selectedInstanceType.MemoryInfo &&
				selectedInstanceType.MemoryInfo.SizeInMiB &&
				selectedInstanceType.MemoryInfo.SizeInMiB <
					this.props.requirements.minRAM
			) {
				this.props.onChange &&
					this.props.onChange({
						...this.props.config,
						instanceType: ""
					});

				this.setState((state: LocalState) => ({
					...state,
					formValidation: {
						...state.formValidation,
						awsEC2InstanceType: {
							isInvalid: true,
							message: `${selectedInstanceType.InstanceType} does not have enough RAM to support selected DB engine.`
						}
					}
				}));
			}
		}
	}

	async fetchEC2Data(accessKeyId: string, secretAccessKey: string) {
		console.log("fetchEC2Data");
		this.setState((state: LocalState) => ({
			...state,
			formValidation: {
				...state.formValidation,
				areAwsCredentialsVerified: false,
				areAwsCredentialsBeingVerified: true
			}
		}));

		if (this.ec2CredentialsVerificationTimeout) {
			console.log("timeout active, clearing");
			clearTimeout(this.ec2CredentialsVerificationTimeout);
			this.ec2CredentialsVerificationTimeout = undefined;
		} else {
			console.log("timeout not active");
		}

		this.ec2CredentialsVerificationTimeout = setTimeout(async () => {
			if (this.ec2CredentialsVerificationTimeout) {
				console.log("timeout finished");
				clearTimeout(this.ec2CredentialsVerificationTimeout);
				this.ec2CredentialsVerificationTimeout = undefined;
			}
			let ec2Regions: Region[] = [];

			try {
				ec2Regions = await AWSUtils.fetchAvailableRegions(
					accessKeyId,
					secretAccessKey
				);
			} catch (error: any) {
				console.warn("Error fetching available EC2 regions:", error);
				this.setState((state: LocalState) => ({
					...state,
					formValidation: {
						...state.formValidation,
						awsAccessKeyID: {
							isInvalid: true,
							message: error.message
						},
						awsSecretAccessKey: {
							isInvalid: true,
							message: error.message
						},
						areAwsCredentialsVerified: false,
						areAwsCredentialsBeingVerified: false
					}
				}));
				return;
			}

			let ec2InstanceTypes: InstanceTypeInfo[] = [];

			try {
				ec2InstanceTypes = await AWSUtils.fetchAvailableInstanceTypes(
					accessKeyId,
					secretAccessKey
				);

				console.log("all instance types", ec2InstanceTypes);
			} catch (error: any) {
				console.warn(
					"Error fetching available EC2 instance types",
					error.message
				);
				this.setState((state: LocalState) => ({
					...state,
					formValidation: {
						...state.formValidation,
						awsAccessKeyID: {
							isInvalid: true,
							message: error.message
						},
						awsSecretAccessKey: {
							isInvalid: true,
							message: error.message
						},
						areAwsCredentialsVerified: false,
						areAwsCredentialsBeingVerified: false
					}
				}));
				return;
			}

			this.setState((state: LocalState) => ({
				...state,
				formValidation: {
					...state.formValidation,
					awsAccessKeyID: undefined,
					awsSecretAccessKey: undefined,
					areAwsCredentialsVerified: true,
					areAwsCredentialsBeingVerified: false
				},
				availableRegions: ec2Regions,
				availableInstanceTypes: ec2InstanceTypes
			}));
		}, 2000);
	}

	render() {
		const {
			formValidation,
			availableRegions,
			availableInstanceTypes,
			formValidation: { areAwsCredentialsVerified }
		} = this.state;
		const { config, readOnly, onChange, classes, requirements } = this.props;
		// console.log("render", formValidation.areAwsCredentialsBeingVerified);
		return (
			<>
				<Grid container item direction="row" spacing={2}>
					<Grid item sm={6} xs={12}>
						<FormControl
							fullWidth={true}
							onInvalid={(e: FormEvent): void => {
								e.preventDefault();
								const form = e.target as HTMLFormElement;

								this.setState((state: LocalState) => ({
									...state,
									formValidation: {
										...state.formValidation,
										awsAccessKeyID: {
											isInvalid: true,
											message: form.validationMessage
										}
									}
								}));
							}}
							error={formValidation.awsAccessKeyID?.isInvalid}
						>
							<TextField
								required
								margin="dense"
								label="AWS Access Key ID"
								error={formValidation.awsAccessKeyID?.isInvalid}
								helperText={formValidation.awsAccessKeyID?.message}
								autoComplete="off"
								inputProps={{
									readOnly: readOnly,
									maxLength: 50,
									"data-cy": "aws-access-key-id"
								}}
								value={config.accessKeyID}
								onChange={(e: ChangeEvent) => {
									const field = e.target as HTMLFormElement;

									onChange &&
										onChange({
											...config,
											accessKeyID: field.value as string
										});

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											awsAccessKeyID: undefined,
											awsSecretAccessKey: undefined
										}
									}));

									if (config.secretAccessKey && field.value) {
										this.fetchEC2Data(field.value, config.secretAccessKey);
									}
								}}
								InputProps={{
									endAdornment:
										(formValidation.areAwsCredentialsVerified && (
											<InputAdornment
												data-cy="aws-secret-credentials-verified"
												position="end"
											>
												<Tooltip title="Verified">
													<VerifiedUser
														className={classes.awsCredentialsVerifiedIcon}
													/>
												</Tooltip>
											</InputAdornment>
										)) ||
										(formValidation.areAwsCredentialsBeingVerified && (
											<InputAdornment position="end">
												<Tooltip title="Verifying">
													<CircularProgress size={16} />
												</Tooltip>
											</InputAdornment>
										))
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item sm={6} xs={12}>
						<FormControl
							fullWidth={true}
							onInvalid={(e: FormEvent): void => {
								e.preventDefault();
								const form = e.target as HTMLFormElement;

								this.setState((state: LocalState) => ({
									...state,
									formValidation: {
										...state.formValidation,
										awsSecretAccessKey: {
											isInvalid: true,
											message: form.validationMessage
										}
									}
								}));
							}}
							error={formValidation.awsSecretAccessKey?.isInvalid}
						>
							<TextField
								required
								type="password"
								name="aws-secret-access-key"
								margin="dense"
								label="AWS Secret Access Key"
								error={formValidation.awsSecretAccessKey?.isInvalid}
								helperText={formValidation.awsSecretAccessKey?.message}
								autoComplete="off"
								inputProps={{
									readOnly: readOnly,
									maxLength: 50,
									"data-cy": "aws-secret-access-key"
								}}
								value={config && config.secretAccessKey}
								onChange={(e: ChangeEvent) => {
									const field = e.target as HTMLFormElement;

									onChange &&
										onChange({
											...config,
											secretAccessKey: field.value as string
										});

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											awsSecretAccessKey: undefined,
											awsAccessKeyID: undefined
										}
									}));

									if (config.accessKeyID && field.value) {
										this.fetchEC2Data(config.accessKeyID, field.value);
									}
								}}
								InputProps={{
									endAdornment:
										(formValidation.areAwsCredentialsVerified && (
											<InputAdornment position="end">
												<Tooltip title="Verified">
													<VerifiedUser
														className={classes.awsCredentialsVerifiedIcon}
													/>
												</Tooltip>
											</InputAdornment>
										)) ||
										(formValidation.areAwsCredentialsBeingVerified && (
											<InputAdornment position="end">
												<Tooltip title="Verifying">
													<CircularProgress size={16} />
												</Tooltip>
											</InputAdornment>
										))
								}}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container item direction="row" spacing={2}>
					<Grid item sm={6} xs={12}>
						<FormControl
							required
							fullWidth={true}
							margin="dense"
							onInvalid={(e: FormEvent): void => {
								e.preventDefault();
								const form = e.target as HTMLFormElement;

								this.setState((state: LocalState) => ({
									...state,
									formValidation: {
										...state.formValidation,
										awsEC2Region: {
											isInvalid: true,
											message: form.validationMessage
										}
									}
								}));
							}}
							error={formValidation.awsEC2Region?.isInvalid}
						>
							<InputLabel htmlFor="aws-region">AWS Region</InputLabel>
							<Select
								data-cy="aws-region-select-container"
								fullWidth={true}
								error={formValidation.awsEC2Region?.isInvalid}
								required
								disabled={!areAwsCredentialsVerified}
								readOnly={readOnly}
								value={config && config.region}
								onChange={(e) => {
									const value = e.target.value as string;

									console.log("selected region", value);

									onChange &&
										onChange({
											...config,
											region: value
										});

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											awsEC2Region: undefined
										}
									}));

									// if credentials are entered, load available instance types
									if (config.accessKeyID && config.secretAccessKey && value) {
										this.setState((state: LocalState) => ({
											...state,
											formValidation: {
												...state.formValidation,
												areAvailableInstanceTypesLoading: true
											}
										}));
										AWSUtils.fetchAvailableInstanceTypes(
											config.accessKeyID,
											config.secretAccessKey,
											value
										).then((data: InstanceTypeInfo[]) => {
											console.log("all instance types", data);

											// clear instance type selection if not available in this region
											const isInstanceTypeStillAvailable =
												config.instanceType &&
												!data.includes({
													InstanceType: config.instanceType
												});
											if (isInstanceTypeStillAvailable) {
												onChange &&
													onChange({
														...config,
														region: value,
														instanceType: ""
													});

												this.setState((state: LocalState) => ({
													...state,
													availableInstanceTypes: data,
													formValidation: {
														...state.formValidation,
														areAvailableInstanceTypesLoading: false,
														awsEC2InstanceType: {
															isInvalid: true,
															message: `'${config.instanceType}' instance is not available in '${config.region}' region`
														}
													}
												}));
											} else {
												this.setState((state: LocalState) => ({
													...state,
													availableInstanceTypes: data,
													formValidation: {
														...state.formValidation,
														areAvailableInstanceTypesLoading: false
													}
												}));
											}
										});
									}
								}}
								inputProps={{
									id: "aws-region",
									"data-cy": "aws-region-select"
								}}
							>
								{availableRegions?.map((region: Region) => (
									<MenuItem
										disabled={region.OptInStatus === "not-opted-in"}
										key={region.RegionName}
										value={region.RegionName}
									>
										{`${region.RegionName}${
											region.OptInStatus === "not-opted-in"
												? " (Not opted in)"
												: ""
										} `}
									</MenuItem>
								))}
							</Select>
							{(formValidation.areAwsCredentialsBeingVerified && (
								<FormHelperText>Loading...</FormHelperText>
							)) ||
								(!readOnly &&
									(!areAwsCredentialsVerified ? (
										<FormHelperText>
											Enter valid AWS Access Key ID and Secret Access Key
										</FormHelperText>
									) : (
										formValidation.awsEC2Region?.isInvalid && (
											<FormHelperText>
												{formValidation.awsEC2Region?.message}
											</FormHelperText>
										)
									)))}
						</FormControl>
					</Grid>

					<Grid item sm={6} xs={12}>
						<FormControl
							required
							fullWidth={true}
							margin="dense"
							onInvalid={(e: FormEvent): void => {
								e.preventDefault();
								const form = e.target as HTMLFormElement;

								this.setState((state: LocalState) => ({
									...state,
									formValidation: {
										...state.formValidation,
										awsEC2InstanceType: {
											isInvalid: true,
											message: form.validationMessage
										}
									}
								}));
							}}
							error={formValidation.awsEC2InstanceType?.isInvalid}
						>
							<InputLabel htmlFor="ec2-instance-type">
								EC2 Instance Type
							</InputLabel>
							<Select
								data-cy="ec2-instance-type-select-container"
								error={formValidation.awsEC2InstanceType?.isInvalid}
								fullWidth={true}
								required
								displayEmpty
								disabled={!areAwsCredentialsVerified}
								readOnly={
									readOnly || formValidation.areAwsCredentialsBeingVerified
								}
								value={config.instanceType}
								onChange={(e) => {
									const value = e.target.value as string;

									onChange &&
										onChange({
											...config,
											instanceType: value
										});

									this.setState((state: LocalState) => ({
										...state,
										formValidation: {
											...state.formValidation,
											awsEC2InstanceType: undefined
										}
									}));
								}}
								inputProps={{
									id: "ec2-instance-type",
									"data-cy": "ec2-instance-type-select"
								}}
							>
								{availableInstanceTypes?.map(
									(instanceType: InstanceTypeInfo) => {
										const isX86 =
											instanceType.ProcessorInfo?.SupportedArchitectures?.includes(
												"x86_64"
											);

										const notEnoughRAM = instanceType.MemoryInfo?.SizeInMiB
											? instanceType.MemoryInfo?.SizeInMiB < requirements.minRAM
											: false;

										const disabled = !isX86 || notEnoughRAM;

										const disableReason =
											(!isX86 && "CPU architecture not supported") ||
											(notEnoughRAM && "Not enough resources");

										return (
											<MenuItem
												disabled={disabled}
												dense={true}
												key={instanceType.InstanceType}
												value={instanceType.InstanceType}
											>
												{instanceType.InstanceType}{" "}
												{!disabled
													? `(${
															instanceType.VCpuInfo?.DefaultVCpus || "N/A"
													  } VCPUs, ${
															(instanceType.MemoryInfo?.SizeInMiB &&
																instanceType.MemoryInfo.SizeInMiB / 1024) ||
															"N/A"
													  } GB RAM)`
													: `(${disableReason})`}
											</MenuItem>
										);
									}
								) || (
									<MenuItem
										key={config.instanceType}
										value={config.instanceType}
									>
										{config.instanceType}
									</MenuItem>
								)}
							</Select>
							{(formValidation.areAwsCredentialsBeingVerified && (
								<FormHelperText>Loading...</FormHelperText>
							)) ||
								(!readOnly &&
									(!areAwsCredentialsVerified ? (
										<FormHelperText>
											Enter valid AWS Access Key ID and Secret Access Key
										</FormHelperText>
									) : (
										formValidation.awsEC2InstanceType?.isInvalid && (
											<FormHelperText>
												{formValidation.awsEC2InstanceType?.message}
											</FormHelperText>
										)
									)))}
						</FormControl>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(EC2ConfigComponent);
